import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common'
import { Subscription } from 'rxjs';

import { DataService } from './../../services/data.service'
import { DataProxyService } from './../../services/data-proxy.service';
import { NavigationService } from './../../services/navigation/navigation.service';
import { SessionStorageService } from './../../services/tdd/session-storage.service';
import { DataServiceManagerService } from './../../services/data-serviceManager.service';
import { TaggingService } from '../../services/tagging.service';
// Constants
import { ConstantsService } from  './../../services/constants.service';

import { MoveModel,  UserModel, ExtractModel, QuestionsModel, LoaderModel,
  BlockModel, CreditCard, ResponseModel, AnswersQuestionsModel,
  MultifolioModel } from './../../models';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import {  LoaderComponent, AlertComponent } from './../../partials/';
import * as _ from 'lodash';
import moment from 'moment';

/**
 * Locked page component.
 *
 * @export
 * @class LockedComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-locked',
  template: require('./locked.component.html'),
  providers: [
      DataService,
      NavigationService,
      DataServiceManagerService,
      TaggingService
  ]
})
export class LockedComponent implements OnInit {

  /**
   * the bloquer status 
   *
   * @type {*}
   * @memberof LockedComponent
   */
  public blocker: any;
  /**
   * the old card 
   *
   * @memberof LockedComponent
   */
  public oldCard = '';
  /**
   * the enviroments detail
   *
   * @private
   * @type {*}
   * @memberof LockedComponent
   */
  private enviroment: any;
  /**
   * the movements 
   *
   * @private
   * @type {*}
   * @memberof LockedComponent
   */
  private moves: any;
  /**
   * the questionarie
   *
   * @private
   * @type {QuestionsModel}
   * @memberof LockedComponent
   */
  private questions: QuestionsModel;
  /**
   * the pan card 
   *
   * @private
   * @type {string}
   * @memberof LockedComponent
   */
  private pan: string;
  /**
   * the response of a request 
   *
   * @private
   * @type {ResponseModel}
   * @memberof LockedComponent
   */
  private responseModel: ResponseModel;
  /**
   * section of the block 
   *
   * @private
   * @type {string}
   * @memberof LockedComponent
   */
  private section: string = 'locked';
  /**
   * flag the dummy mode 
   *
   * @private
   * @memberof LockedComponent
   */
  private dummyMode = false;
  /**
   * flag is busy the service
   *
   * @private
   * @memberof LockedComponent
   */
  private isBusy = false;
  /**
   * flag chager page 
   *
   * @private
   * @memberof LockedComponent
   */
  private isLoading = false;
  /**
   * the filte Description 
   *
   * @private
   * @memberof LockedComponent
   */
  private filterDesc = '';
  /**
   * the current filter 
   *
   * @private
   * @type {string}
   * @memberof LockedComponent
   */
  private currentFilter: string;
  /**
   * the total items 
   *
   * @private
   * @memberof LockedComponent
   */
  private totalItems = 0;
  /**
   * subscription to request
   *
   * @private
   * @type {Subscription}
   * @memberof LockedComponent
   */
  private subscription: Subscription;
  /**
   * subscription to modal 
   *
   * @private
   * @type {Subscription[]}
   * @memberof LockedComponent
   */
  private modalSubscription: Subscription[] = [];
  /**
   * response model 
   *
   * @private
   * @type {ResponseModel}
   * @memberof LockedComponent
   */
  private responseDAO: ResponseModel;
  /**
   * the ref modal 
   *
   * @private
   * @type {BsModalRef}
   * @memberof LockedComponent
   */
  private modalRef: BsModalRef;
  /**
   * spiner overlay 
   *
   * @private
   * @type {LoaderModel}
   * @memberof LockedComponent
   */
  private loader: LoaderModel;

  /**
   * Variable indefinida que setea el tipo de reposicion en el ST
   *
   * @private
   * @memberof LockedComponent
   */
  private repositionType = '';

  /**
   * Creates an instance of LockedComponent.
   * Get the data needed by the UI.
   * @param {DataService} dataService
   * @param {DataProxyService} dataProxy
   * @param {BsModalService} modalService
   * @param {Router} router
   * @param {ConstantsService} constsService
   * @param {NavigationService} navigationService
   * @param {PlatformLocation} location
   * @param {TaggingService} taggingService
   * @memberof LockedComponent
   */
  constructor(
    private dataService: DataService,
    public dataProxy: DataProxyService,
    private modalService: BsModalService,
    private router: Router,
    private constsService: ConstantsService,
    private navigationService: NavigationService,
    private storage : SessionStorageService,
    private location: PlatformLocation,
    private dataServiceManager: DataServiceManagerService,
    private taggingService: TaggingService
  ) {
      this.questions = this.dataProxy.getQuestions();
      this.responseModel  = this.dataProxy.getResponseDAO();
      this.enviroment = _.find(constsService.ENVIROMENT, (item) => {
          return item.env === this.dataProxy.getEnviroment();
        });
      this.executeClarificationRequestL = this.executeClarificationRequestL.bind(this);
      this.executeCardRepositionRequest = this.executeCardRepositionRequest.bind(this);
      // Models
      this.loader = new LoaderModel();
      // Get questions
      this.questions = this.dataProxy.getQuestions();
      // Get the old card number
      this.oldCard = this.dataProxy.getOldCard();
  }

  /**
   * Listen the interaction of the user and validate the native session.
   *
   * @param {Event} $event
   * @memberof LockedComponent
   */
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll($event: Event): void {
      this.navigationService.validateSession();
  }

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof LockedComponent
   */
  public ngOnInit(): void {
    this.dataService.setUris(this.dataProxy.getEnviroment());
    this.dummyMode = this.dataProxy.getDummyMode();
    let selectedMoves: Array<MoveModel> = this.dataProxy.getDataSelected();
    if (selectedMoves) {
    this.totalItems = selectedMoves.length;
    this.moves = _(selectedMoves).groupBy('date').toPairs().value();
    }
    this.dataProxy.getQuestionsStatusService().subscribe((data) => this.handleFooterResponse(data));
    this.questions.lostDate = this.formatDate(this.questions.lostDate) ;
    this.questions.lostDate === 'Invalid date' ? this.questions.lostDate='': this.questions.lostDate=this.questions.lostDate;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    // this.navigationService.setTitle('Bloqueo de tarjeta');
    this.navigationService.tapBack('');
    this.navigationService.hideBackButton();
    this.blocker = this.questions.blocker;
    if((!this.blocker.operationReposition && !this.blocker.operationExpressReposition) || (this.blocker.operationReposition && !this.blocker.operationExpressReposition)){
      this.storage.saveInLocal('respositionType','3');
    }
    this.pan = this.blocker.panReposition.slice(-4);
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.dataProxy.getCreditCardFullData();
    this.taggingService.pageSettings({
      subsection: 'locked'
    },'credit');
    this.taggingService.sendTeliumDataView();
  }

  /**
   * Save option into local storage
   *
   * @private
   * @memberof LockedComponent
   */
  private toggleRepositionType(){
    this.storage.saveInLocal('respositionType',this.repositionType);
  }

  /**
   * Footer handle
   *
   * @private
   * @param {string} v
   * @memberof LockedComponent
   */
  private handleFooterResponse(v: string) {
      if(this.router.url === '/locked'){
        if(!this.blocker.operationReposition && this.blocker.operationExpressReposition && this.repositionType === '2') {
          this.checkTokenL(this.executeCardRepositionRequest);
        } else {
          this.checkTokenL(this.executeClarificationRequestL);
        }
      }
  }

  /**
   * Check token expiration time.
   *
   * @private
   * @param {*} cb
   * @memberof LockedComponent
   */
  private checkTokenL(cb: any) {
    if (this.dummyMode) {
      this.subscription = this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => {
          this.dataProxy.setAccessToken(response.access_token);
          cb(true);
        });
    } else {
      cb(true);
    }
  }

  /**
   * Execute clarification request.
   *
   * @private
   * @returns {(boolean | undefined)}
   * @memberof LockedComponent
   */
  private executeClarificationRequestL(showModal): boolean | undefined  {
    if (!this.isBusy) {
      this.isBusy = true;
    } else {
      this.isBusy = false;
      return false;
    }
    if (this.isBusy) {
      let serviceManagerObject =  this.dataServiceManager.getServiceManager();
      let message = '';
      if(showModal){
        this.openModal('loader');
      }
      if (this.dataProxy.getDummyMode()) {
        /* DUMMY MODE */
        this.subscription = this.dataService.dummyRequest('assets/data/sm-response.json')
          .subscribe((response) => this.handleServiceManagerRequest(response));
      } else {
        /*ALTA DE ACLARACION MODO NORMAL*/
        this.subscription = this.dataService
        .restRequest(
          '/clarifications/',
          'POST',
          JSON.stringify(serviceManagerObject),
          '',
          this.dataProxy.getAccessToken())
        .subscribe((response) => this.handleServiceManagerRequest(response));
      }
    }
  }
  /**
   * Execute card reposition request
   *
   * @private
   * @memberof LockedComponent
   */
  private executeCardRepositionRequest(): void {
    this.openModal('loader');
    if (this.dataProxy.getDummyMode()) {
      /* DUMMY MODE */
      this.subscription = this.dataService.dummyRequest('assets/data/reposition.json')
        .subscribe((response) => {
          this.storage.saveInLocal('cardRepositionResponse',response);
          this.executeClarificationRequestL(false);
        });
    } else {
      /*ALTA DE REPOSICION MODO NORMAL*/
      this.subscription = this.dataService
        .restRequest(
          '/repositions',
          'GET',
          {},
          'cards',
          this.dataProxy.getAccessToken())
        .subscribe((response) => {
          this.storage.saveInLocal('cardRepositionResponse',response);
          this.executeClarificationRequestL(false);
        });
    }
  }

  
  /**
   * Validate if in the response exist a Error.
   *
   * @private
   * @param {*} r
   * @memberof LockedComponent
   */
  private validateSMHandler(r: any){
    // Check if the reponse is an error
    if (!_.isUndefined(r.codigoMensaje)) {
      if (r.codigoMensaje === 'MSG-001') {
        this.dataService.handleError('',{ name: r.mensaje });
      }
    }
    if(r.status==="Error"){
      this.dataService.handleError('',{name: r.status});
    }
  }

  /**
   * Handle service manager request.
   *
   * @private
   * @param {*} r
   * @memberof LockedComponent
   */
  private handleServiceManagerRequest(r: any): void {
    this.validateSMHandler(r);
    let response: ResponseModel = this.dataProxy.getResponseDAO();
    let dateC = '';
    if (this.dataProxy.getDummyMode()) {
      response.setResult(301); // DUMMY
    }
    // Set the current date before the request
    const currentDate = moment().format('DD/MMM/YYYY HH:mm').toString();
    response.setCurrentDate(currentDate.split('.').join(''));
    if (r.Messages) {
      let nationalFolios = [];
      let internationalFolios = [];
      let payment = 'false';
      let date = moment();
      for (let i of r.Messages) {
        if (this.getPayment(i) === 'true') {
          payment = 'true';
        }
        const internationalFolio = this.getFolioDate(i, 'INTERNACIONAL');
        const nationalFolio = this.getFolioDate(i, 'NACIONAL');
        let temporaldate = this.greaterDateValue(internationalFolio, nationalFolio);
        date = this.greaterDateValue(date, temporaldate);

        let tempFoliosQ = this.getFolio(i, 'Internacional');
        let tempArrayF = this.extractFolio(tempFoliosQ);
        internationalFolios = this.concatArray(internationalFolios,tempArrayF);

        tempFoliosQ = this.getFolio(i, 'Nacional');
        tempArrayF = this.extractFolio(tempFoliosQ);
        nationalFolios = this.concatArray(nationalFolios,tempArrayF)
      }
      response.setInternationalFolio(internationalFolios);
      response.setNationalFolio(nationalFolios);
      response.setGreaterDate(date);
      response.setPayment(payment);
      response.setAmount(r.wvrinboxrn.monto * 1);
      response.setResult(301);
    }
    response.setDateCommitment(dateC);
    response.setTotalAmount(this.getTotalAmount());
    response.setName(this.dataProxy.creditCardFullData.userName);
    response.setVisaCard(r.wvrinboxrn.VisaCarta);
    response.setOldCard(this.dataProxy.creditCardFullData.cardNumber);
    this.dataProxy.setResponseDAO(response);
    response.setNewCard(this.dataProxy.questions.blocker.panReposition);
    this.closeModal(() => {
      if(r.wvrinboxrn.AptoDefinitivo === 'true'){
        this.storage.saveInLocal('tdcSmResponse',r);
        this.storage.saveInLocal('tdcSmResponseCleaned',response);
        this.router.navigate(['definitivePayment']);
      }else{
        this.router.navigate(['result']);
      }
    });
  }

  /**
   *method thath receives  strign with folios or null and returns an aaray of folios 
  *
  * @private
  * @param {*} tempFolio
  * @returns
  * @memberof LockedComponent
  */
  private extractFolio(tempFolio){
    let tempArray =[];
    if(tempFolio !== null){
      tempFolio.includes('|') ? tempArray = tempFolio.split('|') : tempArray.push(tempFolio);
    }
    return tempArray;
  }
  /**
  *Method that concats two arrays some like a.concat(b) butin the same scope
  *
  * @private
  * @param {*} origin
  * @param {*} toConcat
  * @returns
  * @memberof LockedComponent
  */
  private concatArray(origin,toConcat){
    toConcat.forEach(function(element) {
      origin.push(element);
    });
    return origin;
  }
  /**
   * Compare dates and return the most away
   * @param {*} international
   * @param {*} national
   * @returns {*}
   * @memberof LockedComponent
   */
  greaterDateValue(international, national): any {
    let greater = null;
    if (international && national) {
      greater = (international.isAfter(national)) ? international : national ;
    } else if (international) {
      greater = international;
    } else if (national) {
      greater = national;
    }
    return greater;
  }

  /**
   * Get total amount.
   *
   * @private
   * @returns {number}
   * @memberof LockedComponent
   */
  private getTotalAmount(): number {
    let totalSum = 0;
    _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
      totalSum += item.amount;
    });
    return totalSum;
  }

  
  /**
   * Get payment.
   *
   * @private
   * @param {string[]} r
   * @returns {*}
   * @memberof LockedComponent
   */
  private getPayment(r: string[], ): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^Abono: `));
    });
    if (!_.isUndefined(find)) {
      return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
    }
    return null;
  }

  /**
   * Get folio date.
   *
   * @private
   * @param {string[]} r
   * @param {string} v
   * @returns {*}
   * @memberof LockedComponent
   */
  private getFolioDate(r: string[], v: string): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^${v} next_breach ==> `));
    });
    if (!_.isUndefined(find)) {
      return moment(find.split('==> ')[1], 'DD/MM/YYYY HH::mm:ss');
    }
    return null;
  }

  /**
   * Get folio.
   *
   * @private
   * @param {string[]} r
   * @param {string} v
   * @returns {*}
   * @memberof LockedComponent
   */
  private getFolio(r: string[], v: string): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^${v}: `));
    });
    if (!_.isUndefined(find)) {
      if(find.split(': ')[1].includes(' ')){
        let a =0;
        let tempFolio='';
        let arrayFolio = find.split(': ')[1].split(' ');
        if(arrayFolio[0]!== 'undefined' && arrayFolio[1]!== 'undefined'){
          return `${arrayFolio[0]}|${arrayFolio[1]}`;
        }else{
          arrayFolio[0]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[0] : a+=1;
          arrayFolio[1]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[1] :  a+=1;
          tempFolio === '' ? tempFolio =  null : a+=1;
          return tempFolio;
        }
      }
      return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
    }
    return null;
  }


  /**
   * Account number
   *
   * @returns {*}
   * @memberof LockedComponent
   */
  accountNumber(): any{
    try {
      return this.dataProxy.getCCData()['cardRec'][0].cardInfo.acctRef.acctId;
    } catch (error) {
      return null;
    }
  }


  /**
   * Format Date with momentJS.
   *
   * @private
   * @param {string} v
   * @returns {string}
   * @memberof LockedComponent
   */
  private formatDate(v: string): string {
    moment.locale('es');
    return moment(v).format('DD/MMM/YYYY').toString().replace(/\./g, '');
  }

  /**
   * Format hour.
   *
   * @private
   * @param {string} h
   * @returns {string}
   * @memberof LockedComponent
   */
  private formatHour(h: string): string {
    if (h === '' || h === null || typeof h === 'undefined') {
      return '00:00:00';
    }else{
      return h.replace('.', ':');
    }
  }

  /**
   * Open a modal instance.
   *
   * @private
   * @param {string} type
   * @memberof LockedComponent
   */
  private openModal(type: string) {
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
  }

  /**
   * Close modal.
   *
   * @private
   * @param {*} [cb]
   * @memberof LockedComponent
   */
  private closeModal(cb?: any) {
    document.getElementById('body').style.removeProperty('overflow');
    setTimeout(() => {
      this.modalRef.hide();
      if (cb) {
        cb();
      };
    }, 1000);
  }

}
