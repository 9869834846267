import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { settings } from 'cluster';
import {SessionStorageService} from '../../../services/tdd/session-storage.service';
import {UtilsTddService} from '../../../services/tdd/utils-tdd.service';
import { LabelsService } from  '../../../services/labels.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
//Taggeo
import { TaggingService } from '../../../services/tagging.service';
import { Route, Router } from '@angular/router';
import { DataProxyService } from '../../../services/data-proxy.service';

/**
 *
 *
 * @export
 * @class ResultTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-result-tdd',
  template: require('./result-tdd.component.html'),
  providers: [
    NavigationService
  ]
})
export class ResultTddComponent implements OnInit, OnDestroy {
  private config: any = {};
  private channel : string = '';
  private amountLabel : string = '';
  private clarificationLabel : string = '';
  private clarificationLabelDescription : string = '';
  private CONSTANTS : any = this.utils.constants();
  private section: string = 'result';
  private configAssigend : any = {};
  private newPan : string = this.storage.getFromLocal('newPan');
  public blocker : any = this.storage.getFromLocal('cardBlocked');
  public whatHappens : boolean = false;
  public cardRepositionResponse = this.storage.getFromLocal('cardRepositionResponse');
  public repositionType = this.storage.getFromLocal('respositionType');
  /**
   * Creates an instance of ResultTddComponent.
   * @param {SessionStorageService} storage
   * @param {UtilsTddService} utils
   * @param {TaggingService} taggingService
   * @param {LabelsService} labelsService
   * @param {NavigationService} navigationService
   * @param {Router} router
   * @param {DataProxyService} dataProxy
   * @memberof ResultTddComponent
   */
  constructor(
    private storage: SessionStorageService,
    private utils: UtilsTddService,
    private taggingService: TaggingService,
    public labelsService: LabelsService,
    private navigationService: NavigationService,
    private router: Router,
    private dataProxy: DataProxyService
  ) {}

  /**
   * Loads initial content.
   *
   * @memberof ResultTddComponent
   */
  ngOnInit() {
    this.channel = this.storage.getFromLocal(this.CONSTANTS.STORAGE.CHANNEL);
    this.utils.scrolltop();
    const serviceResponse : object = this.storage.getFromLocal(this.CONSTANTS.STORAGE.SM_RESPONSE);
    const viewValues: any = this.utils.handleServiceManagerRequest(serviceResponse);
    const whatHappens = this.storage.getFromLocal('viewQuestions');
    if(whatHappens[1].value !== 'Nunca la tuve conmigo') {
      this.whatHappens = true;
    }
    
    this.loadViewValues();
    this.viewConfig(viewValues);
    this.setDataLayer();
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof ResultTddComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    const userdata = this.storage.getFromLocal('userdata');
    this.taggingService.pageSettings({
      subsection: 'result-tdd',
      claimType: this.storage.getFromLocal('viewQuestions'),
      cardType: userdata.cardName
    });
    this.taggingService.sendTeliumDataView();
  }
  /**
   *
   * Config principal view values.
   * 
   * @param {*} viewValues
   * @returns {*}
   * @memberof ResultTddComponent
   */
  viewConfig(viewValues: any) : any {
    if(viewValues.payment) {
      this.amountLabel = this.CONSTANTS.LABELS.PAYMENT_AMOUNT;
      this.clarificationLabel = this.CONSTANTS.LABELS.PAYMENT_DESCRIPTION;
      this.clarificationLabelDescription = this.CONSTANTS.LABELS.PAYMENT_DESCRIPTION_DESCRIPTION;
    } else {
      this.amountLabel = this.CONSTANTS.LABELS.CLARIFICATION_AMOUNT;
      this.clarificationLabel = this.CONSTANTS.LABELS.CLARIFICATION_REGISTER;
      this.clarificationLabelDescription = !this.configAssigend.requirementsResult ? this.CONSTANTS.LABELS.CLARIFICATION_REGISTER_DESCRIPTION : '';
    }
    const folios = this.orderFolios(viewValues);
    const channelLabel = this.channel ==='default' ? this.CONSTANTS.LABELS.SUPERMOBILE : this.CONSTANTS.LABELS.SUPERWALLET;
    this.config = {
      userFullName : viewValues.name,
      dateTime : viewValues.currentDate,
      opinionDate : viewValues.greater,
      folios: folios,
      sentEmailLetter : true,
      channel: channelLabel,
      amount : {
        label : this.amountLabel,
        value : viewValues.totalAmount
      },
      newCard: {
        label: this.CONSTANTS.LABELS.CARD_REPOSITION,
        value: this.newPan
      },
      clarificationLabel : this.clarificationLabel,
      clarificationLabelDescription : this.clarificationLabelDescription
    };
    _.assign(this.config, this.configAssigend);
  }

  /**
   * Set documentation according to reason selected.
   *
   * @memberof ResultTddComponent
   */
  loadViewValues() : void {
    let viewSettings : any = {}
    const clarificationType : any = this.storage.getFromLocal(this.CONSTANTS.STORAGE.QUESTION_ID);
    if(clarificationType.id === '5') {
      viewSettings = {
        requirementsResult : this.utils.getRequirementsResult(this.CONSTANTS.LABELS.RECEIPT_RETURN)
      }
      this.sentInfoTemplate(viewSettings);
    }
  }

  /**
   * Group folios according to location selected by user.
   *
   * @param {*} viewValues
   * @returns {object}
   * @memberof ResultTddComponent
   */
  private orderFolios(viewValues : any) : object {
    let folios : object = {};
    const nationalFolioCount : number = viewValues.nationalFolio.length;
    const internationalFolioCount : number = viewValues.internationalFolio.length;
    if(internationalFolioCount > 0 && nationalFolioCount > 0){
      folios = {
        national : {
          label: nationalFolioCount > 1 ? this.CONSTANTS.LABELS.NATIONALS_FOLIOS : this.CONSTANTS.LABELS.NATIONAL_FOLIO,
          values: viewValues.nationalFolio,
          toShow: this.foliosToString(viewValues.nationalFolio)
        },
        international : {
          label: internationalFolioCount > 1 ? this.CONSTANTS.LABELS.INTERNATIONALS_FOLIOS : this.CONSTANTS.LABELS.INTERNATIONAL_FOLIO,
          values : viewValues.internationalFolio,
          toShow: this.foliosToString(viewValues.internationalFolio)
        }
      };
    } else {
      const foliosMerged : any = _.merge(viewValues.internationalFolio,viewValues.nationalFolio);
      folios = {
        national : {
          label: foliosMerged.length >= 2 ? this.CONSTANTS.LABELS.FOLIOS_NUMBER : this.CONSTANTS.LABELS.FOLIO_NUMBER,
          values: foliosMerged,
          toShow: this.foliosToString(_.merge(viewValues.internationalFolio,viewValues.nationalFolio))
        }
      };
    }
    return folios;
  }
  /**
   * Concat folios in string to set in view
   *
   * @private
   * @param {*} folios
   * @returns {string}
   * @memberof ResultTddComponent
   */
  private foliosToString(folios : any) : string{
    let foliosToString : string = '';
    folios.map((value,index,array)=>{
      foliosToString = index === 0 ? `${foliosToString}${value}` :  `${foliosToString}, ${value}`;
    });
    return foliosToString;
  }

  /**
   * Create the config to show the view to sent info
   *
   * @param {*} viewSettings
   * @memberof ResultTddComponent
   */
  sentInfoTemplate(viewSettings : any) : void {
    this.configAssigend = {
      requirementsResult : viewSettings.requirementsResult,
      warning : true,
      sentEmailLetter : true
    }
  }
  /**
   * Method for the finish button.
   *
   * @memberof ResultComponent
   */
  public finishApp(): void {
    if(this.storage.getFromLocal('isConsumer')){
      this.taggingService.sendTeliumDataLink(
        {
          'interaction_action':'resumen',
          'interaction_label':'finalizar'
        },
        this.storage.getFromLocal('viewQuestions')
      );
    } else {
      let interactionCategory : string = this.storage.getFromLocal('viewQuestions')[1].value === 'TENGO UN RETIRO EN ATM QUE YO NO HICE' 
              ? 'atm_retiro_no_hice' : 'atm_no_dio_dinero_solicite' ;
      this.taggingService.sendTeliumDataLink({
        'interaction_action':'resumen',
        'interaction_label':'finalizar',
        'interaction_category' : interactionCategory
      });
    }
    this.router.navigate(['/welcome-tdd']);
    // this.navigationService.goToRoot();
  }

  public movementList(): void {
    this.dataProxy.internalSession = '';
    this.dataProxy.dataSourceSelected([])
    this.router.navigate(['history']);
  }

  public ngOnDestroy(): void {
    this.dataProxy.internalSession = '';
    this.dataProxy.dataSourceSelected([]);
  }
}
