import {Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatDecimal'
})

export class FormatDecimalPipe implements PipeTransform {
    transform(value: string): string {
        if(!value){
            return '0.00';
        }

        const decimalIndex = value.indexOf('.');
        if (decimalIndex === -1){
            return value;
        }

        const decimalPart = value.slice(decimalIndex + 1);
        if(decimalPart.length === 1){
            return value + '0';
        }

        return value;
    }
}