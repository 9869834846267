import { Component, OnInit,EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TaggingService } from '../../../../services/tagging.service';
import { SessionStorageService } from '../../../../services/tdd/session-storage.service';
/**
 * footes de la aplicacion
 * boton de continuar de la app
 *
 * @export
 * @class FooterTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-footer-tdd',
  template: require('./footer-tdd.component.html'),
})
export class FooterTddComponent implements OnInit {
  /**activo a desactivo el boton */
  @Input() enable: boolean;
  /**texto a mostrar en el boton */
  @Input() buttonText: string;
  /**emisor al padre para continuar */
  @Output() continueClarification = new EventEmitter<any>();

  /**
   *Creates an instance of FooterTddComponent.
  * @param {Router} router
  * @param {TaggingService} taggingService
  * @param {SessionStorageService} storage
  * @memberof FooterTddComponent
  */
  constructor(
    private router: Router,
    private taggingService: TaggingService,
    private storage: SessionStorageService
  ) {}
  /**
   *inicializa el componente
  *
  * @memberof FooterTddComponent
  */
  ngOnInit() {
  }
  /**
   *Method when is clicked the footer
  *
  * @public
  * @memberof FooterTddComponent
  */
  public continue() : void { 
    this.continueClarification.emit();
  }
  /**
   * Go previous page
   *
   * @returns {(void | boolean)}
   * @memberof FooterTddComponent
   */
  public goBack() : void | boolean {
    let linkTeliumValues : any;
    switch (this.getCurrentURL()) {
      case '/welcome-tdd':
        linkTeliumValues = {
          'interaction_action':'detalle_movimientos', 
          'interaction_label':'atras'
        };
        break;
      case '/questionnaire-tdd':
        linkTeliumValues = {
          'interaction_action':'cuestionario', 
          'interaction_label':'atras'
        };
        break;
      case '/summary-tdd':
        linkTeliumValues = {
          'interaction_action':'confirmacion', 
          'interaction_label':'atras'
        };
        break;
      case '/locked-tdd':
        const interactionAction : string = 
          this.storage.getFromLocal('respositionType') === "1" ? "reposicion_inmediata" : "reposicion_personalizada";;
        linkTeliumValues = {
          'interaction_action': interactionAction, 
          'interaction_label':'atras'
        };
        
        case '/questionnaire-atm':
        linkTeliumValues = {
          'interaction_action': 'Movimientos',
          'interaction_label':'atras'
        };
        break;
        case '/summary-atm':
          linkTeliumValues = {
            'interaction_action': 'Cuestionario',
            'interaction_label':'atras'
          };
        break;
        case '/single-sign-on':
          linkTeliumValues = {
            'interaction_action':'detalle', 
            'interaction_label':'atras'
          };
          break;
      default:
        break;
    }
    this.taggingService.sendTeliumDataLink(
      linkTeliumValues,
      this.storage.getFromLocal('viewQuestions')
    );
    if(this.getCurrentURL()!== '/welcome-tdd') {
      history.back();
      return false;
    } else {
      //TODO: change value according to TDD
      window.parent.postMessage('ACLA', '*');
    }
  }
  /**
   * Gets the current URL.
   *
   * @returns {string}
   * @memberof FooterComponent
   */
  public getCurrentURL(): string {
    let currentUrl: string = this.router.url.toString();
    if (currentUrl.lastIndexOf('?') > -1) {
      currentUrl = currentUrl.substr(0, currentUrl.lastIndexOf('?'));
    }
    currentUrl = currentUrl.substr(currentUrl.lastIndexOf('/'), currentUrl.length);
    return currentUrl;
  }
  /**
   * Get the current page.
   *
   * @returns {string}
   * @memberof FooterComponent
   */
  public getCurrentPage(): string {
    let url = this.getCurrentURL();
    return url.replace(/\//, '');
  }
}
