import { Response } from '@angular/http';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {SessionStorageService} from '../../../services/tdd/session-storage.service';
import {UtilsTddService} from '../../../services/tdd/utils-tdd.service';
import { DataService } from '../../../services/data.service';
import { NavigationService } from '../../../services/navigation/navigation.service';

// Alertas
import { AlertsTddService } from '../../../services/tdd/alerts-tdd.service';
import { DataProxyService } from '../../../services/data-proxy.service';
//Taggeo
import { TaggingService } from '../../../services/tagging.service';
//Components
import { LoaderComponent, AlertComponent } from '../../../partials/';
//Models
import { MoveModel,  UserModel, ExtractModel, QuestionsModel, LoaderModel,
  BlockModel, ResponseModel, AnswersQuestionsModel,
  MultifolioModel, MultifolioModelComplete } from '../../../models';
/**
 * componente que da el resumen de los movimnientos y respuestas del usuario
 * da de alta la aclaracion
 *
 * @export
 * @class SummaryTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-summary-tdd',
  template: require('./summary-tdd.component.html'),
  providers: [
    DataService,
    NavigationService,
  ]
})
export class SummaryTddComponent implements OnInit, OnDestroy {


  /**
   *
   * subscripcion a servicios para obtener una respuesta y 
   * llevar el contenido a la vista 
   * 
   * @type {Subscription}
   * @memberof SummaryTddComponent
   */
  subscription: Subscription;

  /** cuestionario de la vista */
  private viewQuestions=[];
  /**
   * Show user data
   *
   * @memberof SummaryTddComponent
   */
  public userdata = this.storage.getFromLocal('userdata');
  /**
   * Get movements selected
   *
   * @memberof SummaryTddComponent
   */
  public movements = this.storage.getFromLocal('multifolio');
  /**
   * subscripcion a evento de bloqueo 
   *
   * @private
   * @type {Subscription[]}
   * @memberof SummaryComponent
   */
  private modalSubscription: Subscription[] = [];
  /**
   * Modal component 
   *
   * @private
   * @type {BsModalRef}
   * @memberof SummaryComponent
   */
  private modalRef: BsModalRef;
  /**
   * Flag to show modal and make clarification
   *
   * @private
   * @type {boolean}
   * @memberof SummaryTddComponent
   */
  private cancelCardBlockFlag : boolean = false;
  /**
   * Indicate the dummy mode status
   *
   * @private
   * @type {string}
   * @memberof SummaryTddComponent
   */
  private dummyMode : string = this.storage.getFromLocal('dummy');
  /**
   * spiner que se muestra mientras contesta un servicio 
   *
   * @private
   * @type {LoaderModel}
   * @memberof SummaryComponent
   */
  private loader: LoaderModel;
  /**
   * Check if the user no reported card
   *
   * @private
   * @type {boolean}
   * @memberof SummaryTddComponent
   */
  private checkNoReportedStoleFlow : boolean;
  /**
   * Check if the user reported card
   *
   * @private
   * @type {boolean}
   * @memberof SummaryTddComponent
   */
  private checkReportedStoleFlow : boolean;
  /**
   *Creates an instance of SummaryTddComponent.
   * @param {UtilsTddService} utils
   * @param {SessionStorageService} storage
   * @param {DataService} dataService
   * @param {Router} router
   * @param {AlertsTddService} alertsTddService
   * @param {TaggingService} taggingService
   * @param {NavigationService} navigationService
   * @param {BsModalService} modalService
   * @param {DataProxyService} dataProxy
   * @memberof SummaryTddComponent
   */
  constructor(
    private utils:UtilsTddService,
    private storage: SessionStorageService,
    private dataService: DataService,
    private router: Router,
    private alertsTddService : AlertsTddService,
    private taggingService: TaggingService,
    private navigationService: NavigationService,
    private modalService: BsModalService,
    public dataProxy: DataProxyService
  ) {
    /** 
     * Models    
     */ 
    this.loader = new LoaderModel();
  }
  
  /**
   * navega al inicio de la pagina
   * consulta el cuestionario a mostrar del storage
   * inicializa el servicio de urls
   * consulta el canal de la aplicacion
   *
   * @memberof SummaryTddComponent
   */
  ngOnInit() {
    this.utils.scrolltop();
    this.setDescriptionEnd();
    this.dataService.setUris(this.storage.getFromLocal('enviroment'));
    this.setDataLayer();
    this.dataProxy.getQuestionsStatusService().subscribe((data) => {
      data === 'executeCardBlock' ?
        this.closeModal(() => {
          this.tokenValidator(true);
        })
        : this.cancelCardBlockWarning();
    });
    //Recibir mensaje bloqueo
    this.subscription = this.alertsTddService.getMessage()
      .subscribe(
        message => {
          if(message.response.number === -2){
            this.continueBlock();
          }
        }
    )
    
  }

  ngOnDestroy(): void {
    this.closeModal();
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof SummaryTddComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.taggingService.pageSettings({
      subsection: 'summary-tdd',
      claimType: this.storage.getFromLocal('viewQuestions'),
      cardType: this.userdata.cardName
    });
    this.taggingService.sendTeliumDataView();
  }
  /**
   * Remove description and put to the end of array.
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private setDescriptionEnd():void{
    let selectedQuestions : Array<any> = this.storage.getFromLocal('viewQuestions');
    let selectedQuestionDescription : any = {};
    selectedQuestions.forEach((currentValue, index, array)=>{
      if(currentValue.key === 'Descripción'){
        selectedQuestionDescription = currentValue;
        array.splice(index,1);
      }
    });
    if(Object.keys(selectedQuestionDescription).length > 0){ selectedQuestions.push(selectedQuestionDescription); }
    this.viewQuestions = selectedQuestions;
    this.checkNoReportedStoleFlow = this.viewQuestions[1].value === 'Me la robaron o la extravié y no la he reportado' ? true: false;
    this.checkReportedStoleFlow = this.viewQuestions[1].value === 'La reporté como robada o extraviada' ? true: false;
  }
  /**
   * Check if is fraud flow and then show block modal.
   * If else make request to token validator and clarification service
   * 
   *
   * @public
   * @memberof SummaryTddComponent
   */
  public executeContinue() : void {
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'confirmacion',
        'interaction_label':'confirmar'
      },
      this.viewQuestions
    );
    if(!this.cancelCardBlockFlag) {
      //Check if the user chose 'no' in '¿tengo la tarjeta en mi poder?'
      if(this.viewQuestions[1].id === 'commerceInteract' && this.viewQuestions[1].value === 'NO') {
        this.validateCardLock('fraud');
      } else if(this.viewQuestions[0].value === 'No'){
        this.checkCardReport();
      } else {
        this.tokenValidator(false)
      } 
    } else {
      this.tokenValidator(false);
    }
  }
  /**
   * Check the what happen with the card
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private checkCardReport() : void {
    if(this.checkNoReportedStoleFlow) {
      this.validateCardLock('noReported');
    } else if(this.checkReportedStoleFlow){
      this.validateCardLock('reported');
    } else {
      this.tokenValidator(false);
    }
  }
  /**
   * Method that checks:
   *  * If the flow requires to lock cards.
   *
   * @memberof SummaryComponent
   */
  public async validateCardLock(flagCardReport : string) {
    if (this.dummyMode) {
      /* DUMMY MODE */  
      this.dataService.dummyRequest('assets/data/check-cards.json')
        .subscribe((response) => {
          this.checkFraduStoleFlow(response, flagCardReport);
        });
    } else {
      let SMObject = this.utils.generateSMObject();
      /*Consulta bloqueo en MODO NORMAL*/
      this.subscription = this.dataService
        .restRequest('/check-cards/', 'POST',
          { 
            multifolio: this.getMultifolioModelComplete(),
            cuestionario: this.getQuestionsCheckCards(SMObject.wvrinboxrn.cuestionario)
          }, 'user',
          this.storage.getFromLocal('app-access'),
          this.storage.getFromLocal('client'))
        .subscribe((response) => {
          this.checkFraduStoleFlow(response, flagCardReport);
        });
    }
  }
  /**
   * Check card and question answered to show overlay,
   * make claim or block card
   *
   * @private
   * @param {*} response
   * @param {string} flagCardReport
   * @memberof SummaryTddComponent
   */
  private checkFraduStoleFlow(response: any,flagCardReport : string){
    if(flagCardReport === 'fraud'){
      response.data === 'true' ? this.openModal('block-one') : this.tokenValidator(false);  
    } else {
      response.data === 'true' ? this.checkCardNotBlocking(flagCardReport) : this.checkCardBlocking(flagCardReport);
    }
  }
  /**
   * If card is active check what happened
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private checkCardNotBlocking(flagCardReport : string) : void {
    if(flagCardReport === 'reported') {
      this.openModal('no-locked');
    } else if(flagCardReport === 'noReported'){
      this.openModal('block-two');
    }
  }
  /**
   * If card is blocked check what happened
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private checkCardBlocking(flagCardReport : string) : void {
    if(flagCardReport === 'reported') {
      this.tokenValidator(false)
    } else if(flagCardReport === 'noReported') {
      this.openModal('cant-block');
    }
  }
  /**
   *
   *Invoca al modelo de Multifolio para poder enviar todos los datos de cada moviemiento
  *y mostrar el resumen de lo que se va a calarar
  * @returns
  * @memberof SummaryComponent
  */
  private getMultifolioModelComplete() {
    let multifolio = [];
    let ix = 0;
    this.dataProxy.getDataSelected().forEach(element => {
      let objmultifolio = new MultifolioModelComplete();
      objmultifolio.TxrCodigoCom = element.txrCodigoCom;
      objmultifolio.TxrComercio = element.txrComercio;
      objmultifolio.TxrDivisa = element.txrDivisa;
      objmultifolio.TxrFecha = element.txrFecha;
      objmultifolio.TxrHrTxr = this.hourFormat(element.txrHrTxr);
      objmultifolio.TxrModoEntrada = element.txrModoEntrada;
      objmultifolio.TxrMonto = element.txrMonto;
      objmultifolio.TxrMovExtracto = element.txrMovExtracto;
      objmultifolio.TxrNumExtracto = element.txrNumExtracto;
      objmultifolio.TxrReferencia = element.txrReferencia;
      objmultifolio.TxrSucursalAp = element.txrSucursalAp;
      objmultifolio.TxrTipoFactura = element.txrTipoFactura;
      objmultifolio.TxrPAN = element.txrPAN; 
      multifolio.push(objmultifolio);
      ix++;
    });
    return multifolio;
  }
  /**
   * Formating hour.
   *
   * @private
   * @param {string} h
   * @returns {string}
   * @memberof SummaryComponent
   */
  private hourFormat(hour: string): string {
    if (hour === '' || hour === null || typeof hour === 'undefined') {
      return '00:00:00';
    }else{
      return hour.replace('.', ':');
    }
  }
  /**
   *
   * Get the firts answer of questionary and then show 
   * the next steps for each question
   *
   * @private
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getQuestionsCheckCards(questionsSelected : object) : object {
    let questionarieGenerated = {}
    if(this.viewQuestions[1].value === 'LA REPORTÉ COMO ROBADA O EXTRAVIADA') {
      questionarieGenerated = [
        {
          Preguntas : '¿Tiene la tarjeta en su poder?',
          Respuestas : 'NO'
        },
        {
          Preguntas : '¿Qué sucedió con su tarjeta?',
          Respuestas : 'La reporté como robada o extraviada.'
        }
      ];
    } else if(this.viewQuestions[1].value === 'ME LA ROBARON O LA EXTRAVIÉ Y NO LA HE REPORTADO'){
      questionarieGenerated = [
        {
          Preguntas : '¿Tiene la tarjeta en su poder?',
          Respuestas : 'NO'
        },
        {
          Preguntas : '¿Qué sucedió con su tarjeta?',
          Respuestas : 'Me la robaron o la extravié y no la he reportado.'
        }
      ];
    } else {
      questionarieGenerated = questionsSelected;
    }
    return questionarieGenerated;
  }
  /**
   * Validate token and excecute clarification service 
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private tokenValidator(blockFlag : boolean) : void {
    if (this.dummyMode) {
      this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => {
          this.storage.saveInLocal('app-access',response.access_token);
          blockFlag ? this.executeCardBlock() : this.executeClarification();
        });
    } else {
      blockFlag ? this.executeCardBlock() : this.executeClarification();
    }
  }
  /**
   * Make request to card block
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private executeCardBlock() : void {
    const blockMotive = this.viewQuestions[1].value === 'Me la robaron o la extravié y no la he reportado' ? 'robo' : 'fraude';
    this.loader.setMessage('Estamos bloqueando su tarjeta, un momento por favor.');
    this.openModal('loader');
    if (this.dummyMode || this.storage.getFromLocal('nolock')) {
        /* DUMMY MODE */
        this.subscription = this.dataService.dummyRequest('assets/data/lock-card.json')
          .subscribe((response) => this.handleCardBlockResponse(response));
    } else {
      const endpoint = `/lock/lock_code/${blockMotive}/path/front`;
      //TODO: remove when complete testing
      /* this.handleCardBlockResponse({
        "operationReposition":true,
        "operationExpressReposition":false,
        "operationCancellation":true,
        "operationRepositionMsg":"112345@AVMPA0166 OK. OPERACION EFECTUADAÓ@DCMPM0408 P5579077000021756          5579070108915011      202511202504                                                    Ó",
        "operationCancellationMsg":"@112345@AVMPA0166 OK. OPERACION EFECTUADAÓ@AVMPA0393 NO HA PODIDO SER COMUNICADOÓ@DCMPM0401 P5579070108915011      01                              Ó",
        "status":"Ok",
        "panReposition":"5579077000021756"
      }); */
      this.subscription = this.dataService
      .restRequest(
        endpoint,
        'POST',
        '',
        'cards',
        this.storage.getFromLocal('app-access'),
        this.storage.getFromLocal('client'))
      .subscribe(
        (response) => {
          this.handleCardBlockResponse(response)
        },
        (error) => { 
          this.storage.saveInLocal('cardBlocked',{
            operationReposition : false,
            operationCancellation : false,
            operationCancellationMsg : 'no fue posible cancelar la tarjeta',
            operationRepositionMsg : 'no fue posible reponer la tarjeta'
          });
          this.closeModal(() => {
            this.openModal('cancelExecuteBlock');
          });
      });
    }
  }

  /**
   * Handle card block response Handle card block response.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private handleCardBlockResponse(response: any) {
    if(response.status==='Ok'){
      let blocker: BlockModel = response;
      this.closeModal(() => {
        this.storage.saveInLocal('cardBlocked',blocker);
        this.continueBlock();
      });
      /* if (blocker.operationCancellation) {
        this.closeModal(() => {
          this.storage.saveInLocal('cardBlocked',blocker);
          this.continueBlock();
        });
      } else {
        this.closeModal(() => {
          this.openModal('cancelExecuteBlock');
        });
      } */
    }else{
      this.closeModal(() => {
        this.openModal('cancelExecuteBlock');
      });
    } 
  }

  /**
   * hace el llamado al endpoint para dar de alta la aclaracion
   * dependiendo el ambiente o el modo dummy
   * 
   * hace navegacion a la pantalla de respuesta
   *
   * @memberof SummaryTddComponent
   */
  executeClarification(){
    this.openModal('loader');
    if(this.dummyMode){
      this.dataService.dummyRequest('assets/data/sm-response-tdd.json')
        .subscribe((response) => {
          this.closeModal(() => {
            this.storage.saveInLocal('SMResponse',response);
            this.router.navigate(['result-tdd']);
          });
        });
    }else{
      let SMObject = this.utils.generateSMObject();
      this.dataService.restRequest(
        '/clarifications/',
        'POST',
        JSON.stringify(SMObject),
        '',
        this.storage.getFromLocal('app-access'),
        this.storage.getFromLocal('client'))
      .subscribe((response) => {
        this.closeModal(() => {
          this.storage.saveInLocal('SMResponse',response);
          this.router.navigate(['result-tdd']);
        });
      });
    }
  }

  /**
   *
   * set the abs of the pay of movement clarificaded  
   * @param {*} cant
   * @returns
   * @memberof SummaryTddComponent
   */
  removeSign(cant : any){
    return Math.abs(cant);
  }

  //Continuar Bloqueo
  public continueBlock(){
    this.router.navigate(['locked-tdd']);
  }
  /**
   * Open a modal instance.
   *
   * @private
   * @param {string} type
   * @memberof SummaryComponent
   */
  private openModal(type: string): void {
    this.navigationService.tapBack('');
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
  }
  /**
   * Close modal.
   *
   * @private
   * @param {*} [cb]
   * @memberof SummaryComponent
   */
  private closeModal(cb?: any): void {
    this.navigationService.tapBack('summary');
    document.getElementById('body').style.removeProperty('overflow');
    setTimeout(() => {
      this.modalRef.hide();
      if (cb) {
        cb();
      };
    }, 1000);
  }
  private cancelCardBlockWarning() : void {
    this.cancelCardBlockFlag = true;
    this.closeModal(() => {
      this.openModal('blockCancel');
    });
  }

}
