import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DataObjectService } from './../../services/data-object.service';
// Services
import { DataProxyService } from './../../services/data-proxy.service';
import { DataService } from './../../services/data.service';
import { LabelsService } from  './../../services/labels.service';
import { NavigationService } from './../../services/navigation/navigation.service';
import { SessionStorageService } from './../../services/tdd/session-storage.service';
import { TaggingService } from '../../services/tagging.service';

import { ResponseModel, QuestionsModel } from './../../models/';
import { QualityRatingComponent } from '../../partials/quality-rating/quality-rating.component';

import moment from 'moment';
import * as _ from 'lodash';

/**
 *
 *
 * @export
 * @class ResultComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-result',
  template: require('./result.component.html'),
  providers: [
    DataObjectService,
    DataService,
    NavigationService,
    TaggingService
  ]
})
export class ResultComponent implements OnInit, OnDestroy {

  /**
   * contenedor de preguntas 
   *
   * @private
   * @type {QuestionsModel}
   * @memberof ResultComponent
   */
  private questions: QuestionsModel;
  /**
   * mensaje de data layer 
   *
   * @private
   * @type {string}
   * @memberof ResultComponent
   */
  private section: string = 'result';
  /**
   * modelo de response de servicio 
   *
   * @private
   * @type {ResponseModel}
   * @memberof ResultComponent
   */
  private responseModel: ResponseModel;
  /**
   * codigo de estatus 
   *
   * @private
   * @memberof ResultComponent
   */
  private statusCode = 0;
  /**
   * arreglo de datos 
   *
   * @private
   * @type {*}
   * @memberof ResultComponent
   */
  private rawData: any;
  /**
   * mensaje de error 
   *
   * @private
   * @type {string}
   * @memberof ResultComponent
   */
  private errorMessage: string;
  /**
   * si esta disponible 
   *
   * @private
   * @memberof ResultComponent
   */
  private isEnabled = false;
  /**
   * modelo de referencia 
   *
   * @private
   * @type {BsModalRef}
   * @memberof ResultComponent
   */
  private modalRef: BsModalRef;
  
  /**
   * Obtiene le tipo de reposicion
   *
   * @private
   * @memberof ResultComponent
   */
  private repositionType = this.storage.getFromLocal('respositionType');
  /**
   * Obtiene el valor de respuesta para la reposicion
   *
   * @private
   * @memberof ResultComponent
   */
  private cardRepositionResponse = this.storage.getFromLocal('cardRepositionResponse');
  /**
   * Obtiene el valor de ST sobre el blqoueo de trajeta
   *
   * @private
   * @memberof ResultComponent
   */
  private checkBlock = this.storage.getFromLocal('checkBlock');

  

  /**
   * Visa carta
   *
   * @type {string}
   * @memberof ResultComponent
   */
  public visaCarta : string = 'false';

  /**
   * Creates an instance of ResultComponent.
   * @param {Router} router
   * @param {DataObjectService} dataObject
   * @param {DataProxyService} dataProxy
   * @param {DataService} dataService
   * @param {LabelsService} labelsService
   * @param {BsModalService} modalService
   * @param {NavigationService} navigationService
   * @param {TaggingService} taggingService
   * @memberof ResultComponent
   */
  constructor(
    private router: Router,
    private dataObject: DataObjectService,
    public dataProxy: DataProxyService,
    private dataService: DataService,
    private labelsService: LabelsService,
    private modalService: BsModalService,
    private navigationService: NavigationService,
    private taggingService: TaggingService,
    private storage: SessionStorageService) {
  }

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof ResultComponent
   */
  public ngOnInit() {
    this.setDataLayer();
    // Navigation rules
    // this.navigationService.setTitle('Folio de Aclaración');
    this.navigationService.tapBack('');
    this.navigationService.hideBackButton();
  
    this.dataService.setUris(this.dataProxy.getEnviroment());
    this.responseModel = this.dataProxy.getResponseDAO();
    this.statusCode = this.responseModel.getResult();
    this.questions = this.dataProxy.getQuestions();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.visaCarta = this.responseModel.getVisaCard();
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.dataProxy.getCreditCardFullData();
    this.taggingService.pageSettings({
      subsection: 'result'
    },'credit');
    this.taggingService.sendTeliumDataView();
  }

  /**
   * Listen the interaction of the user and validate the native session.
   *
   * @private
   * @param {Event} $event
   * @memberof ResultComponent
   */
  @HostListener('window:scroll', ['$event'])
  private onWindowScroll($event: Event): void {
    this.navigationService.validateSession();
  }

  /**
   * Method for the finish button.
   *
   * @memberof ResultComponent
   */
  public finishApp(): void {
    // this.openQualityRatingModal();
    this.router.navigate(['/welcome']);
  }
  /**
   * Open the Quality Rating modal.
   *
   * @memberof ResultComponent
   */
  public openQualityRatingModal(): void {
    const options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'quality-rating-modal'
    };
    this.modalRef = this.modalService.show(QualityRatingComponent, options);
  }

  /**
   * Angular Lifecycle hook: When the component it is destroyed.
   *
   * @memberof ResultComponent
   */
  public ngOnDestroy() {
    this.dataProxy.internalSession = '';
    this.dataProxy.dataSourceSelected([]);
  }

  
}
